<template>
  <form
    :action="data.action"
    :id="data.id"
    class="form form-submit"
    method="POST"
  >
    <template v-for="(field, index) in data.fields">
      <FormGroup
        :key="index + '-field'"
        :data="field"
        :id="data.id"
        @changeOption="changeOption"
      />
      <div v-if="field.conditional" :key="index + '-conditional'">
        <FormConditionalGroup
          v-if="Object.keys(field.conditional).length > 0"
          :key="index + '-conditional'"
          :data="field.conditional"
          :id="data.id"
        />
      </div>
    </template>
    <div :id="recaptchaId"></div>
    <span class="form__message">{{ recaptchaError }}</span>
    <ButtonSubmit
      :btnClass="'submitBtn-' + data.id + ' primary-btn '"
      btnLabel="Submeter pedido"
      :id="data.id"
      @submit="submit"
    />
  </form>
</template>

<script>
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import FormGroup from "@/components/Forms/FormGroup.vue";
import FormConditionalGroup from "@/components/Forms/FormConditionalGroup.vue";

import { submitForm } from "@/functions/forms.js";

export default {
  data() {
    return {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      recaptchaId: "recaptcha",
      recaptchaResponse: null,
      recaptchaError: "",
    };
  },
  mounted() {
    this.loadCaptcha();
  },

  name: "Form",
  props: {
    data: { type: Object },
  },
  components: {
    ButtonSubmit,
    FormGroup,
    FormConditionalGroup,
  },
  methods: {
    concatAgileLeads(formEl, destination) {
      let mappedFields = [];
      if (destination == "agileleads") {
        mappedFields = [
          "name",
          "phone",
          "email",
          "model",
          "oidDealer",
          "plate",
        ];
      } else {
        mappedFields = ["name", "phone", "email", "oidDealer", "moreinfo"];
        const moreInfo = formEl.querySelector('[name="moreinfo"]');
        moreInfo.value = moreInfo.value == "1" ? "S" : "N";
      }

      const fields = formEl.querySelectorAll(
        "input:not([type='hidden']),select"
      );

      let obs = "";

      fields.forEach((f) => {
        if (f.name == "contactType") {
          if (f.value == "N" || f.value == "E") {
            formEl.querySelector('[name="vehicleType"]').value = "N";
          } else {
            formEl.querySelector('[name="vehicleType"]').value = "U";
          }
        }
        if (!mappedFields.includes(f.name)) {
          if (f.type == "checkbox") {
            if (f.checked) {
              if (f.parentElement.dataset.label) {
                obs += "Label: " + f.parentElement.dataset.label + " - ";
              }
              obs += "Value: " + f.value + " || ";
            }
          } else if (f.type == "radio") {
            if (f.checked) {
              if (f.parentElement.dataset.label) {
                obs += "Label: " + f.parentElement.dataset.label + " - ";
              }
              obs += "Value: " + f.value + " || ";
            }
          } else {
            obs +=
              "Label: " + f.dataset.label + " - Value: " + f.value + " || ";
          }
        }
      });

      obs = obs.replaceAll("(", "/");
      obs = obs.replaceAll(")", "");

      const newInput = document.createElement("input");
      newInput.name = "allFields";
      newInput.type = "hidden";
      newInput.value = obs;
      formEl.append(newInput);
    },
    submit() {
      if (this.recaptchaResponse == null) {
        //alert("Por favor, preencha o reCAPTCHA.");
        this.recaptchaError = "Por favor, preencha o reCAPTCHA.";
        return;
      }
      this.recaptchaError = "";
      const form = document.querySelector("#" + this.data.id);
      const destination = document.querySelector(
        "#" + this.data.id + "-destination"
      );
      if (destination) {
        this.concatAgileLeads(form, destination.value);
      }

      submitForm(this.data.id);
    },
    changeOption(e) {
      this.$emit("changeOption", e);
    },
    loadCaptcha() {
      window.onReCaptchaLoad = () => {
        window.grecaptcha.render(this.recaptchaId, {
          sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          callback: this.onVerify,
          "expired-callback": this.onExpired,
        });
      };

      const recaptchaScript = document.createElement("script");
      recaptchaScript.src =
        "https://www.google.com/recaptcha/api.js?onload=onReCaptchaLoad&render=explicit";
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      document.head.appendChild(recaptchaScript);
    },
    onVerify(response) {
      this.recaptchaResponse = response;
      //console.log("reCAPTCHA response:", response);
    },
    onExpired() {
      this.recaptchaResponse = null;
      //console.log("reCAPTCHA expired");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
